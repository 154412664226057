import request from '../utils/request'

export default {
  async devicedTotal () {   // 获取设备统计数据
    const ret = await request('/v1/stat/device_total', {},'POST')
    return ret
  },
  async alarm () {    // 获取告警数据
    const ret = await request('/v1/stat/alarm', {},'POST')
    return ret
  },
  async devicedList () {  // 获取设备列表
    const ret = await request('/v1/stat/device_list', {},'POST')
    return ret
  }
}
