<template>
  <div>
    <el-card class="box-card">
      <h3>
        <b>{{ $t('index.device_stat') }}</b>
        <span @click="toStatPage">
          {{ $t('index.more') }}
          <i class="el-icon-d-arrow-right"></i>
        </span>
      </h3>
      <div class="deviceStat">
        <ul>
          <li>
            <p>{{ $t('index.device_total') }}</p>
            <span>{{ total }}</span>
          </li>
          <li>
            <p>{{ $t('index.new_add_today') }}</p>
            <span>{{ increase }}</span>
          </li>
          <li>
            <p>{{ $t('index.online') }}</p>
            <span>{{ online }}</span>
          </li>
          <li>
            <p>{{ $t('index.offline') }}</p>
            <span>{{ outline }}</span>
          </li>
        </ul>
      </div>
    </el-card>
    <el-card class="box-card">
      <h3>
        <b>{{ $t('index.alarm_info') }}</b>
        <span @click="toAlarmPage">
          {{ $t('index.more') }}
          <i class="el-icon-d-arrow-right"></i>
        </span>
      </h3>
      <div class="deviceStat">
        <el-table :data="tableData" :stripe="true" style="width: 100%">
          <el-table-column :label="$t('index.username')" prop="name"></el-table-column>
          <el-table-column :label="$t('index.device')" prop="imei" width="200"></el-table-column>
          <el-table-column :label="$t('index.battery_level')" prop="volt" width="120"></el-table-column>
          <el-table-column :label="$t('index.online_status')" prop="online" width="120"></el-table-column>
          <el-table-column :label="$t('index.alarm_type')" prop="type_tip" width="200"></el-table-column>
          <el-table-column :label="$t('index.alarm_content')" prop="content" width="200"></el-table-column>
          <el-table-column :label="$t('index.alarm_time')" prop="create_at" width="200"></el-table-column>
          <el-table-column :label="$t('index.positioning')" prop="create_at" width="100">
            <template #default="scope">
              <el-button type="text" size="small" v-if="$store.state.pageAction.indexOf('index.positioning')> -1"
                         @click="locationEdit(scope.row)">{{ $t('index.positioning') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialogTit" center v-model="localtionDialog" width="70%">
          <div class="mapcont" style="position:relative;">
            <div class="map container" id="container" style="width:100%;min-height:550px;">
              <div class="deviceMes">
                <p>
                  <span>{{ $t('index.device_type') }}</span>
                  {{ locationData.type }}
                </p>
                <p>
                  <span>IMEI</span>
                  {{ locationData.imei }}
                </p>
                <p>
                  <span>{{ $t('index.location_info') }}</span>
                  {{ locationData.location }}
                </p>
                <p>
                  <span>{{ $t('index.battery_level') }}</span>
                  {{ locationData.volt }}%
                </p>
                <p>
                  <span>{{ $t('index.comm_time') }}</span>
                  {{ locationData.update_at }}
                </p>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import index from "../model/index";
import device from "../model/device";

export default {
  name: "index",
  data() {
    return {
      total: "",
      increase: "",
      online: "",
      outline: "",
      location: [],
      tableData: [],
      localtionDialog: false,
      dialogTit: '',
      locationData: {
        is_online: 0,
        type: "",
        imei: "",
        location: "",
        volt: "",
        update_at: "",
        lat: "",
        lon: ""
      }
    };
  },
  async mounted() {
    // await this.loadMapData()
    await this.devicedTotal();
    await this.alarm();
    // setTimeout(() => {
    //   this.$modal(() => import('./test.vue'), () => {
    //     console.log('ok')
    //   }, () => {
    //     console.log('cancel')
    //   }, {})
    // }, 2000)
  },
  methods: {
    async devicedTotal() {
      const ret = await index.devicedTotal();
      if (ret.code === 200) {
        this.total = ret.data.total;
        this.increase = ret.data.new;
        this.online = ret.data.online;
        this.outline = ret.data.outline;
      }
    },
    async alarm() {
      const ret = await index.alarm();
      if (ret.code === 200) {
        this.tableData = ret.data.list;
      }
    },
    async loadMapData() {
      const ret = await device.map({});
      if (ret.code === 200) {
        this.location = ret.data;
        setTimeout(() => {
          this.mapInit();
        }, 5000);
      } else {
        this.$message.warning(ret.message);
      }
    },
    locationEdit(row) {
      this.locationData.is_online = row.is_online;
      this.locationData.type = row.device_type;
      this.locationData.imei = row.imei;
      this.locationData.volt = row.volt;
      this.locationData.update_at = row.create_at;
      this.locationData.location = row.location;
      this.locationData.lat = row.latitude;
      this.locationData.lon = row.longitude;
      this.localtionDialog = true;
      this.loadMap();
    },
    toStatPage() {
      this.$router.push("/device/home");
    },
    toAlarmPage() {
      this.$router.push("/alarm/home");
    },
    toLocationPage() {
      this.$router.push("/device/location");
    },
    loadMap() {
      AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
          .then(AMap => {
            let map = new AMap.Map("container", {
              center: [this.locationData.lon, this.locationData.lat],
              zoom: 13
            });
            let trafficLayer = new AMap.TileLayer.Traffic({
              zIndex: 2
            });
            let marker = new AMap.Marker({
              icon:
                  this.locationData.is_online !== 1
                      ? this.$store.state.outline
                      : this.$store.state.online,
              position: [this.locationData.lon, this.locationData.lat],
              title: this.locationData.location
            });
            // 将以上覆盖物添加到地图上
            // 单独将点标记添加到地图上
            map.add(marker);
            map.add(trafficLayer); //添加图层到地图
          })
          .catch(e => {
            console.log(e);
          });
    }
  },
};
</script>
<style scoped>
.el-card {
  margin-bottom: 20px;
}

.el-card h3 {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin: 15px 20px;
}

.el-card h3 span {
  font-weight: normal;
  cursor: pointer;
}

.el-card ul {
  display: flex;
  justify-content: space-between;
}

.el-card ul li {
  width: 24%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  color: #ffffff;
}

.el-card ul li p {
  font-size: 28px;
  line-height: 42px;
  padding: 32px 0px 16px 0px;
}

.el-card ul li span {
  font-size: 22px;
  padding-bottom: 22px;
}

.el-card ul li:first-child {
  background-image: linear-gradient(-180deg, #ffae8f 0%, #ff6695 100%);
}

.el-card ul li:nth-child(2) {
  background-image: linear-gradient(-180deg, #88c3ff 0%, #a065ff 100%);
}

.el-card ul li:nth-child(3) {
  background-image: linear-gradient(-180deg, #7bf7e9 0%, #48a8e3 100%);
}

.el-card ul li:nth-child(4) {
  background-image: linear-gradient(-180deg, #d9dce2 0%, #6a717c 100%);
}

.deviceMes {
  position: absolute;
  right: 0;
  top: 0px;
  background: rgba(255, 255, 255, 0.8);
  width: auto;
  padding: 20px 20px 10px 0px;
  height: 160px;
  z-index: 999999;
  padding-top: 15px;
}

.deviceMes p {
  line-height: 30px;
  color: #666;
}

.deviceMes p span {
  display: inline-block;
  width: 80px;
  margin-right: 20px;
  text-align: right;
  color: #333;
}
</style>
